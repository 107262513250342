import React from 'react';

import Layout from '@components/organisms/Layout';
import NotFoundTemplate from '@components/templates/NotFoundTemplate';

import { NextPage } from 'next';

const NotFound: NextPage = () => {
  return (
    <Layout>
      <NotFoundTemplate />
    </Layout>
  );
};

export default NotFound;

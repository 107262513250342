import React, { FC, useState } from 'react';

import styled from 'styled-components';

import Box from '@components/atoms/Box/Box';
import NotFoundMessage from '@components/molecules/NotFoundMessage';

import { ROUTE } from '@utils/index';

const InnerWrapper = styled(Box)`
  max-width: 35.625em;
  margin-left: auto;
  margin-right: auto;
  padding-left: 0.875em;
  padding-right: 0.875em;
`;

const Video = styled.video`
  width: 100%;
`;

const NotFoundTemplate: FC = () => {
  const [hasMessage, displayMessage] = useState(false);

  const handleChangePage = () => {
    window.location.replace(ROUTE.PRICING);
  };

  const handleVideo = () => {
    displayMessage(true);
  };

  return (
    <InnerWrapper>
      {hasMessage && <NotFoundMessage onChangePage={handleChangePage} />}
      <Video
        muted
        autoPlay
        onEnded={handleVideo}
        src="https://cdn.3d4medical.com/store/videos/404error.mp4"
      />
    </InnerWrapper>
  );
};

export default NotFoundTemplate;

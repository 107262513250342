import React, { FC } from 'react';

import styled, { keyframes } from 'styled-components';

import Box from '@components/atoms/Box/Box';
import Button from '@components/atoms/Button/Button';
import Text from '@components/atoms/Text/Text';

import { Align } from '@shared/enums/align';
import { I18nTranslation } from '@shared/interfaces/i18n';
import { breakpoint } from 'theme';
import { withTranslation } from 'i18n';

const fadeInDown = keyframes`
  0% {
    opacity: 0;
    transform: translate3d(0,-100%,0)
  }

  to {
    opacity: 1;
    transform: translateZ(0)
  }
`;

const Wrapper = styled(Box)`
  padding-top: 2em;
  position: absolute;
  left: 0;
  right: 0;
  z-index: 1;
  animation-name: ${fadeInDown};
  animation-duration: 1s;

  ${breakpoint.m`
    padding-top: 8em;
  `}
`;

export type NotFoundMessageTypes = {
  onChangePage: () => void;
  t: I18nTranslation;
};

const NotFoundMessage: FC<NotFoundMessageTypes> = ({ onChangePage, t }) => {
  return (
    <Wrapper>
      <Box align={Align.Center} mb={0.5}>
        <Text
          weight="bold"
          smSize={1.25}
          size={1.5}
          color="cornflowerBlue"
          lineHeight="normal"
          opacity={0.3}
        >
          {t('title')}
        </Text>
      </Box>
      <Box align={Align.Center} mb={1}>
        <Text
          lineHeight="normal"
          weight="bold"
          smSize={2}
          size={3.25}
          color="cornflowerBlue"
        >
          {t('joke')}
        </Text>
      </Box>
      <Box align={Align.Center} mb={2}>
        <Text color="cornflowerBlue" lineHeight={1.5} smSize={1.25} size={1.5}>
          {t('text')}
        </Text>
      </Box>
      <Box align={Align.Center}>
        <Button variant="ctaHero" onClick={onChangePage}>
          {t('homepage')}
        </Button>
      </Box>
    </Wrapper>
  );
};

export default withTranslation('404')(NotFoundMessage);
